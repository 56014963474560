import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

class Contact extends Component {
  render() {
    return (
      <section id="contact">
        <div className="row section-intro">
          <Fade top>
            <div className="col-twelve">
              <h5>Contact</h5>
              <h1>I'd love to hear from you.</h1>
            </div>
          </Fade>
        </div> {/* /section-intro */}
        <div className="row contact-form">
          <div className="col-twelve">
            <Zoom>
              {/* form */}
              <form action="https://send.pageclip.co/1Db0WpldDkhD3ufpg7ShwkC0fV6Kt97V/Portfolio" className="pageclip-form" method="post">
                <fieldset>
                  <div className="form-field">
                    <input name="contactName" type="text" id="contactName" placeholder="name" minLength={2} required />
                  </div>
                  <div className="form-field">
                    <input name="contactEmail" type="email" id="contactEmail" placeholder="email" required />
                  </div>
                  <div className="form-field">
                    <input name="contactSubject" type="text" id="contactSubject" placeholder="subject" />
                  </div>                       
                  <div className="form-field">
                    <textarea name="contactMessage" id="contactMessage" placeholder="message" rows={10} cols={50} required />
                  </div>                      
                  <div className="form-field">
                    <button className="submitform pageclip-form__submit" type="submit"><span>Submit</span></button>
                    <div id="submit-loader">
                      <div className="text-loader">Sending...</div>                             
                      <div className="s-loader">
                        <div className="bounce1" />
                        <div className="bounce2" />
                        <div className="bounce3" />
                      </div>
                    </div>
                  </div>
                </fieldset>
              </form> {/* Form End */}
            </Zoom>  
            {/* contact-success */}
            <div id="message-success">
              <i className="fa fa-check" />Your message was sent, thank you!<br />
            </div>
          </div> {/* /col-twelve */}
        </div> {/* /contact-form */}
        <div className="row contact-info">
          <Zoom>
            <div className="col-four tab-full">
              <div className="icon">
                <a href="mailto:doris@dorislee.tech"><i className="fa fa-envelope-square" /></a>
              </div>
              <h5>Email me at</h5>
              <p>doris@dorislee.tech</p>
            </div>
          </Zoom>
          <Zoom>
            <div className="col-four tab-full">
              <div className="icon">
                <a href="https://www.linkedin.com/in/csdoris/" target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin-square" /></a>
              </div>
              <h5>Connect with me</h5>
              <p>csdoris</p>
            </div>
          </Zoom>
          <Zoom>
            <div className="col-four tab-full">
              <div className="icon">
                <a href="https://www.github.com/csdoris/" target="_blank" rel="noopener noreferrer"><i className="fa fa-github-square" /></a>
              </div>
              <h5>Check out my repos</h5>
              <p>@csdoris</p>
            </div>
          </Zoom>
        </div> {/* /contact-info */}
      </section>
    )
  }
}

export default Contact;
