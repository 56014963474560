import React, { Component } from 'react';
import { Link } from 'react-scroll';

class Intro extends Component {
  render() {
    return (
      <section id="intro" style={{height: `${window.innerHeight}px`}}>
        <div className="intro-overlay" />	
        <div className="intro-content">
          <div className="row">
            <div className="col-twelve">
              <h5>Hello, World.</h5>
              <h1>I'm Doris Lee.</h1>
              <p className="intro-position">
                <span>Project Manager</span>
                <span>Data Analyst</span>
                <span>Software Developer</span>
              </p>
              <Link className="button stroke" to="about" spy={true} smooth={true} offset={30} duration={500}>More About Me</Link>
            </div>  
          </div>   		 		
        </div> {/* /intro-content */} 
        <ul className="intro-social">
          <li><a href="mailto:doris@dorislee.tech"><i className="fa fa-envelope" /></a></li>
          <li><a href="https://www.linkedin.com/in/csdoris/" target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin" /></a></li>
          <li><a href="https://www.github.com/csdoris/" target="_blank" rel="noopener noreferrer"><i className="fa fa-github" /></a></li>
        </ul> {/* /intro-social */}      	
      </section>
    )
  }
}

export default Intro;
