import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import Work from '@material-ui/icons/Work';
import School from '@material-ui/icons/School';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import Flight from '@material-ui/icons/Flight';
import StarIcon from '@material-ui/icons/Star';
import 'react-vertical-timeline-component/style.min.css';

class Resume extends Component {
  render() {
    return (
      <section id="resume" className="grey-section">
        <div className="row section-intro">
          <Fade top>
            <div className="col-twelve">
              <h5>Resume</h5>
              <h1>Brief summary of my credentials.</h1>
            </div>
          </Fade>
        </div>

        <VerticalTimeline>

        <VerticalTimelineElement
            className="vertical-timeline-element"
            date="Jul 2020"
            dateClassName="resume-date"
            iconStyle={{ background: '#72BBF0', color: '#fff' }}
            icon={<Work />}
            position="right"
          >
            <h3 className="vertical-timeline-element-title">Consulting Intern</h3>
            <h4 className="vertical-timeline-element-subtitle">KPMG New Zealand</h4>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="vertical-timeline-element"
            date="Jun 2020"
            dateClassName="resume-date"
            iconStyle={{ background: '#72BBF0', color: '#fff' }}
            icon={<Work />}
            position="right"
          >
            <h3 className="vertical-timeline-element-title">Software Tester</h3>
            <h4 className="vertical-timeline-element-subtitle">University of Auckland</h4>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="vertical-timeline-element"
            date="May 2020"
            dateClassName="resume-date"
            iconStyle={{ background: '#9BC724', color: '#fff' }}
            icon={<StarIcon />}
            position="left"
          >
            <h3 className="vertical-timeline-element-title">Microsoft Azure Virtual Hackathon</h3>
            <p>Shortlisted for Phase 2 by designing a Smart Farming solution using IoT and machine learning</p>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="vertical-timeline-element"
            date="Mar 2020 - Present"
            dateClassName="resume-date"
            iconStyle={{ background: '#FF6163', color: '#fff' }}
            icon={<MenuBookIcon />}
            position="left"
          >
            <h3 className="vertical-timeline-element-title">Master of Information Technology</h3>
            <h4 className="vertical-timeline-element-subtitle">University of Auckland</h4>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="vertical-timeline-element"
            date="Jan 2020 - Present"
            dateClassName="resume-date"
            iconStyle={{ background: '#72BBF0', color: '#fff' }}
            icon={<Work />}
            position="right"
          >
            <h3 className="vertical-timeline-element-title">Math Tutor</h3>
            <h4 className="vertical-timeline-element-subtitle">Kumon Education Centre, Royal Oak</h4>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="vertical-timeline-element"
            date="Nov 2019"
            dateClassName="resume-date"
            iconStyle={{ background: '#9BC724', color: '#fff' }}
            icon={<Flight />}
            position="left"
          >
            <h3 className="vertical-timeline-element-title">Arrived New Zealand: Kia ora</h3>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="vertical-timeline-element"
            date="May 2015 - Oct 2019 (4 years 6 months)"
            dateClassName="resume-date"
            iconStyle={{ background: '#72BBF0', color: '#fff' }}
            icon={<Work />}
            position="right"
          >
            <h3 className="vertical-timeline-element-title">Project Manager</h3>
            <h4 className="vertical-timeline-element-subtitle">Hong Kong Polytechnic University</h4>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="vertical-timeline-element"
            date="May 2014 - May 2015 (1 year 1 month)"
            dateClassName="resume-date"
            iconStyle={{ background: '#72BBF0', color: '#fff' }}
            icon={<Work />}
            position="right"
          >
            <h3 className="vertical-timeline-element-title">Project Manager</h3>
            <h4 className="vertical-timeline-element-subtitle">Hong Kong University of Science and Technology</h4>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="vertical-timeline-element"
            date="Feb 2012 - May 2014 (2 years 4 months)"
            dateClassName="resume-date"
            iconStyle={{ background: '#72BBF0', color: '#fff' }}
            icon={<Work />}
            position="right"
          >
            <h3 className="vertical-timeline-element-title">Assistant Project Manager</h3>
            <h4 className="vertical-timeline-element-subtitle">Hong Kong Polytechnic University</h4>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="vertical-timeline-element"
            date="Sep 2009 - Jan 2012 (2 years 5 months)"
            dateClassName="resume-date"
            iconStyle={{ background: '#72BBF0', color: '#fff' }}
            icon={<Work />}
            position="right"
          >
            <h3 className="vertical-timeline-element-title">Assistant Project Officer</h3>
            <h4 className="vertical-timeline-element-subtitle">Hong Kong Polytechnic University</h4>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="vertical-timeline-element"
            date="Dec 2005 - Sep 2009 (3 years 10 months)"
            dateClassName="resume-date"
            iconStyle={{ background: '#72BBF0', color: '#fff' }}
            icon={<Work />}
            position="right"
          >
            <h3 className="vertical-timeline-element-title">Project Coordinator</h3>
            <h4 className="vertical-timeline-element-subtitle">Chinese University of Hong Kong</h4>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="vertical-timeline-element"
            date="Dec 2003 - Dec 2005 (2 years 1 month)"
            dateClassName="resume-date"
            iconStyle={{ background: '#72BBF0', color: '#fff' }}
            icon={<Work />}
            position="right"
          >
            <h3 className="vertical-timeline-element-title">Software Developer</h3>
            <h4 className="vertical-timeline-element-subtitle">Clarity Language Consultants Limited</h4>
          </VerticalTimelineElement>

          <VerticalTimelineElement
            className="vertical-timeline-element"
            date="Class of 2003"
            dateClassName="resume-date"
            iconStyle={{ background: '#FF6163', color: '#fff' }}
            icon={<School />}
            position="left"
          >
            <h3 className="vertical-timeline-element-title">BEng in Computer Science</h3>
            <h4 className="vertical-timeline-element-subtitle">Hong Kong University of Science and Technology</h4>
          </VerticalTimelineElement>
        </VerticalTimeline>
      </section>
    )
  }
}

export default Resume;
