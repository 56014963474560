import React, { Component } from 'react';
import { Link } from 'react-scroll';
import Fade from 'react-reveal/Fade';

class About extends Component {
  render() {
    return (
      <section id="about">  
        <div className="row section-intro">
          <Fade top>
            <div className="col-twelve">
              <h5>About</h5>
              <h1>Let me introduce myself.</h1>
              <div className="intro-info">
                <img src="images/profile-pic.jpg" alt="Profile Pic" />
                <p className="lead">I enjoy crunching data and making sense of it. Before coming to New Zealand, I worked at the Hong Kong Polytechnic University and analysed the alumni and donation data to better facilitate alumni engagement and communication, as well as improve fundraising results by making data-informed decisions.</p>
              </div>   			
            </div>
          </Fade>
        </div> {/* /section-intro */}
        <div className="row about-content">
          <Fade left delay={500}>
            <div className="col-six tab-full">
              <h3>Profile</h3>
              <p>Certified project manager with over 7 years of experience in implementing enterprise-level IT projects, managing databases, analysing data and preparing documents and presentations for business-reporting to senior management.</p>
              <ul className="info-list">
                <li>
                  <strong>Name:</strong>
                  <span>Doris Lee</span>
                </li>
                <li>
                  <strong>Location:</strong>
                  <span>Auckland, New Zealand</span>
                </li>
                <li>
                  <strong>Website:</strong>
                  <span>www.dorislee.tech</span>
                </li>
                <li>
                  <strong>Email:</strong>
                  <span>doris@dorislee.tech</span>
                </li>
              </ul> {/* /info-list */}
            </div>
          </Fade>
          <Fade right delay={500}>
            <div className="col-six tab-full">
              <h3>Skills</h3>
              <p>Now pursuing a masters degree in Information Technology at the University of Auckland to upskill myself in emerging technologies, including data science, AI, machine learning, IoT and cloud computing.</p>
              <ul className="info-list">
                <li>
                  <strong>Project Management</strong>
                  <span>Project Management Professional (PMP),<br />PRINCE2 2017 Practitioner</span>
                </li>
                <li>
                  <strong>Data Analysis</strong>
                  <span>Python, R</span>
                </li>
                <li>
                  <strong>Database Management</strong>
                  <span>Microsoft SQL Server, MySQL, MongoDB, Firebase</span>
                </li>
                <li>
                  <strong>Software Development</strong>
                  <span>React, React Native, JavaScript, Bootstrap, HTML, CSS, PHP, Java</span>
                </li>
              </ul> {/* /skill-bars */}		
            </div>
          </Fade>
        </div>
        <div className="row button-section">
          <div className="col-twelve">
            <Link className="button stroke" to="contact" spy={true} smooth={true} offset={30} duration={500}>Get in touch</Link>
            <Link className="button button-primary" to="resume" spy={true} smooth={true} offset={30} duration={500}>See my resume</Link>
          </div>   		
        </div>
      </section>
    )
  }
}

export default About;
