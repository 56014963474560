import React, { Component } from 'react';

import NavBar from './components/NavBar/NavBar';
import Intro from './components/Intro/Intro';
import About from './components/About/About';
import Resume from './components/Resume/Resume';
import Portfolio from './components/Portfolio/Portfolio';
import Interests from './components/Interests/Interests';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';

class App extends Component {
  render() {
    return (
      <div className="App">
        <NavBar />
        <Intro />
        <About />
        <Resume />
        <Portfolio />
        <Interests />
        <Contact />
        <Footer />
      </div>
    );
  }
}

export default App;
