import React, { Component } from "react";
import { Link, animateScroll as scroll } from "react-scroll";

class NavBar extends Component {
  state = {
    opacity: 0,
    showInterests: (window.innerWidth>=650),
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll');
    window.removeEventListener('resize');
  }

  handleResize = () => {
    this.setState({showInterests: (window.innerWidth >= 650)});
  }

  handleScroll = () => {
    if (window.scrollY > 450) {
      this.setState({opacity: 1});
    } else {
      this.setState({opacity: window.scrollY/450});
    }
  }

  scrollToTop = () => {
    scroll.scrollToTop();
  };

  render() {
    let navStyle = {background: `rgba(0, 0, 0, ${this.state.opacity})`};
    return (
      <nav className="nav" id="navbar" style={navStyle}>
        <div className="nav-content">
          <ul className="nav-items">
            <li className="nav-item">
              <Link activeClass="active" to="about" spy={true} smooth={true} offset={30} duration={500}>About</Link>
            </li>
            <li className="nav-item">
              <Link activeClass="active" to="resume" spy={true} smooth={true} offset={30} duration={500}>Resume</Link>
            </li>
            <li className="nav-item">
              <Link activeClass="active" to="portfolio" spy={true} smooth={true} offset={30} duration={500}>Portfolio</Link>
            </li>
            {(this.state.showInterests) && (
              <li className="nav-item">
                <Link activeClass="active" to="interests" spy={true} smooth={true} offset={30} duration={500}>Interests</Link>
              </li>
            )}
            <li className="nav-item">
              <Link activeClass="active" to="contact" spy={true} smooth={true} offset={30} duration={500}>Contact</Link>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

export default NavBar;
