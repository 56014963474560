import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

const interests = [
  {
    delay: 250,
    title: 'Volleyball',
    desc: 'A lot of teamwork',
    image: 'images/interests/volleyball.jpg',
  },
  {
    delay: 500,
    title: 'Volunteering',
    desc: 'Socialise with locals',
    image: 'images/interests/market.jpg',
  },
  {
    delay: 750,
    title: 'Outdoors',
    desc: 'For a stroll or hike',
    image: 'images/interests/hiking.jpg',
  },
  {
    delay: 1000,
    title: 'Cooking',
    desc: 'Try out recipes',
    image: 'images/interests/cooking.jpg',
  },
]

class Interests extends Component {
  render() {
    return (
      <section id="interests">
        <div className="row section-intro">
          <Fade top delay={0}>
            <div className="col-twelve">
              <h5>Interests</h5>
              <h1>What (else) I like to do.</h1>
            </div>
          </Fade>
        </div>
        <div className="row">
          {interests.map((item,index) => (
            <Zoom delay={item.delay}>
            <div id="interests-wrapper" className="col-three tab-full" key={index}>
              <div className="item">
                <div className="item-wrap">
                  <img src={item.image} alt={item.title} />
                  <div className="overlay" />                       
                    <div className="portfolio-item-meta">
                      <h3>{item.title}</h3>
                      <p>{item.desc}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Zoom>
          ))}
        </div> {/* /row */}
      </section>
    )
  }
}

export default Interests;
