import React, { Component } from 'react';
import { Link } from 'react-scroll';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

const projects = [
  {
    delay: 300,
    title: 'Live Data Viewer',
    desc: 'A dashboard visualising the worldwide situation of COVID-19. I used React to create the web app frontend, and wrote a Python script to retrieve the data from an API daily.',
    tags: ['Data Visualisation', 'Website', 'React', 'Python'],
    image: 'images/portfolio/covid-dashboard.png',
    url: 'https://covid-dashboard-db.web.app',
    repo: 'https://github.com/csdoris/covid-dashboard-3-db',
  },
  {
    delay: 500,
    title: 'Weed Detector Mobile App',
    desc: 'A mobile app that detects weeds from a photo. I trained a machine learning algorithm to classify images of seedlings into crop or weeds with Microsoft Azure Custom Vision service.',
    tags: ['Machine Learning', 'Mobile App', 'React Native', 'Expo'],
    image: 'images/portfolio/weed-detector.jpg',
    repo: 'https://github.com/csdoris/weed-detector',
  },
]

class Portfolio extends Component {
  render() {
    return (
      <section id="portfolio">
        <div className="row section-intro">
          <Fade top delay={0}>
            <div className="col-twelve">
              <h5>Portfolio</h5>
              <h1>Check out some of my works.</h1>
            </div>
          </Fade>
        </div>
        <div className="container">
          <div className="project-wrapper">
            {projects.map((project,index) => (
              <div className="row" key={index}>
                <Zoom delay={project.delay}>
                  <div className="col-six tab-full">
                    <div className="project-wrapper-text">
                      <h2 className="project-wrapper-text-title">{project.title}</h2>
                      <div>
                        <p className="project-wrapper-text-content">
                          {project.tags.map((tag, index) => (
                            <code style={{marginRight: '10px'}} key={index}>{tag}</code>
                          ))}
                        </p>
                        <p className="project-wrapper-text-content">
                          {project.desc}
                        </p>
                      </div>
                      {project.url && (
                        <a className="button stroke small" href={project.url} target="_blank" rel="noopener noreferrer">
                          See Live
                        </a>
                      )}
                      {project.repo && (
                        <a className="button button-primary small" href={project.repo} target="_blank" rel="noopener noreferrer">
                          Source Code
                        </a>
                      )}
                    </div>
                  </div>
                  <div className="col-six tab-full">
                    <div className="project-wrapper-image">
                      <Link to="#">
                        <div data-tilt className="thumbnail rounded">
                          <img className="img-fluid" src={project.image} alt={project.title} />
                        </div>
                      </Link>
                    </div>
                  </div>
                </Zoom>
              </div>
            ))}
          </div>
        </div>
      </section>
    )
  }
}

export default Portfolio;
