import React, { Component } from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';

class Footer extends Component {
  state = {
    showGoToTop: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll');
  }

  handleScroll = () => {
    if (window.scrollY > 250) {
      this.setState({showGoToTop:true})
    } else {
      this.setState({showGoToTop:false})
    }
  }

  scrollToTop = () => {
    scroll.scrollToTop();
  };

  render() {
    let goToTopStyle = (this.state.showGoToTop) ? {display: 'block'} : {display: 'none'};
    return (
      <footer>
        <div className="row">
          <div className="col-twelve">
            <div className="copyright">
              Created with <i className="fa fa-heart" /> by Doris Lee. Design adapted from <Link style={{cursor: 'pointer'}} to="#" onClick={() => {var newTab = window.open(); newTab.opener = null; newTab.location = 'http://www.styleshout.com/';}}>styleshout</Link>.
            </div>		                  
          </div>
          <div id="go-top" style={goToTopStyle}>
            <Link to="#" onClick={this.scrollToTop}><i className="fa fa-long-arrow-up" /></Link>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer;
